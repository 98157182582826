<template>
  <div class="l_bottom">
    <div class="lb_title">
      <a href="https://beian.miit.gov.cn">京ICP备2023004034号-1</a>
    </div>

    <div
      v-show="hasScroll"
      class="bottom_scroll"
      @click="toTop"
    ><i
        style="color:#fff;font-size:3rem;line-height: 4rem;"
        class="el-icon-arrow-up"
      ></i></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props:{
    hasScroll:{
      // type:Blean,
      default: true
    }
  },
  mounted() {},
  methods: {
    toTop() {
      // console.log(123123);
      this.$emit('getTop');
      
    }
  }
};
</script>

<style lang="scss" scoped >
.l_bottom {
  background: #fff;
  border-top: 0.1rem solid #999;
  width: 100vw;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .lb_title {
    width: 128.4rem;
    font-size: 1.6rem;
    font-weight: 300;
    color: #666666;
    line-height: 3rem;
    text-align: center;
  }
  .bottom_scroll {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 6rem;
    height: 4rem;
    background: #000000;
    opacity: 0.5;
    position: absolute;
    right: 3rem;
    top: 1rem;
  }
}
</style>
